<template>
  <div class="content container" >
    <img src="/img/maintenance-center.png" alt="">
    <p class="text">
      Ошибка подключения. Пожалуйста, повторите попытку
    </p>
    <a class="back-button" href="https://homebank.kz">Вернуться в Homebank</a>
  </div>
</template>

<script>

import eventLogger from "../services/eventLogger";

export default {
  async mounted(){
    await eventLogger('general_technical_work')
  }
}
</script>

<style scoped>
  .content{
    background-image: url("/img/maintenance.svg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .content img {
    width: 50%;
  }
  .text{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }
  .back-button{
    background: linear-gradient(143deg, #0AB72A 0%, #008C62 100%);
    /* Elevation/Elevation 3 */

    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 15px 50px;
    margin-top: 15px;
    font-family: 'Manrope';
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    /* or 50% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Day/Text/onPrimary */

    color: #FFFFFF;

  }
</style>